/* import __COLOCATED_TEMPLATE__ from './convert-to-paid-checkout.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { keepLatestTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { post } from 'embercom/lib/ajax';
import {
  BILLING_PERIODS,
  FIN_AI_COPILOT_BASE_ID,
  PRICING_5_X_CORE_ADVANCED_ID,
  PRICING_5_X_CORE_ESSENTIAL_ID,
  PROACTIVE_SUPPORT_PLUS_BASE_ID,
  defaultSolutionId,
  plansFromSolution,
} from 'embercom/lib/billing';
import { PricingModelIdentifier } from 'embercom/lib/billing/pricing-models';
import type CardlessTrialService from 'embercom/services/cardless-trial-service';
import { DEFAULT_REQUEST_PARAMS } from 'embercom/services/quote-service';
import { INTENT_TYPES } from 'embercom/components/stripe/stripe-component';

import type { AfterPaymentMethodSuccess } from 'embercom/components/stripe/stripe-component';
import type Router from '@ember/routing/router-service';
import { type TaskGenerator } from 'ember-concurrency';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import type Plan from 'embercom/models/plan';
import type QuoteService from 'embercom/services/quote-service';

interface Args {
  solutionId: number;
}
interface Signature {
  Element: any;
  Args: Args;
}

export default class ConvertToPaidCheckout extends Component<Signature> {
  @service declare appService: any;
  @service declare cardlessTrialService: CardlessTrialService;
  @service declare quoteService: QuoteService;
  @service declare customerService: any;
  @service declare intl: any;
  @service declare notificationsService: any;
  @service declare store: any;
  @service declare router: Router;

  @service declare purchaseAnalyticsService: any;

  @tracked billingPeriod = BILLING_PERIODS.Monthly;
  @tracked selectedSeatNumber = 1;
  @tracked seatNumber = 1;
  @tracked selectedProactiveAddon = false;
  @tracked copilotInitialised = false;
  @tracked selectedCopilotAdmins: string[] = [];
  @tracked showCreditCardPage = false;
  @tracked paymentMethod = '';
  @tracked selectedPlanId = '';
  @tracked solutionId? = Number(this.args.solutionId);

  INTENT_TYPES = INTENT_TYPES;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.billingPeriod = this.appService.app.teamPurchaseExperienceCheckoutDefaultAnnual
      ? BILLING_PERIODS.Annual
      : BILLING_PERIODS.Monthly;
    if (!this.solutionId) {
      this.solutionId = Number(defaultSolutionId(PRICING_5_X_CORE_ADVANCED_ID));
    }
    this.selectedPlanId = plansFromSolution(this.solutionId)[0];
    this.setSeatNumberToWithinMinAndMax();
    taskFor(this.getQuotes).perform();
    this.selectedCopilotAdmins = this.adminIdsWithCopilotSeats;
  }

  get minimumSeatNumber() {
    let hasAssignedCoreSeats = this.appService.app.humanAdminsWithCoreSeat.length;
    let notViewingEssential = this.selectedPlanId !== PRICING_5_X_CORE_ESSENTIAL_ID;

    return Number(
      hasAssignedCoreSeats && notViewingEssential
        ? this.appService.app.humanAdminsWithCoreSeat.length
        : this.appService.app.humanAdmins.length,
    );
  }

  get maximumSeatNumber() {
    return this.billingPeriod === BILLING_PERIODS.Annual ? 100 : this.minimumSeatNumber;
  }

  private setSeatNumberToWithinMinAndMax() {
    if (
      this.selectedSeatNumber < this.minimumSeatNumber ||
      this.seatNumber < this.minimumSeatNumber
    ) {
      this.selectedSeatNumber = this.minimumSeatNumber;
      this.seatNumber = this.selectedSeatNumber;
    }
    if (this.seatNumber > this.maximumSeatNumber) {
      this.seatNumber = this.maximumSeatNumber;
      if (!this.appService.app.canUseMultipleMonthlySeatsExperiment) {
        this.selectedSeatNumber = this.maximumSeatNumber;
      }
    }
  }

  get queryParamsForRedirect() {
    return {
      solution_id: this.solutionId,
      plan_id: this.selectedPlanId,
      cardless_trial: true,
      billing_period: this.billingPeriod,
      seat_number: this.seatNumber,
      proactive_addon: this.selectedProactiveAddon,
      copilot_addon: this.selectedCopilotAddon,
    };
  }

  get redirectUrl() {
    let baseUrl = window.location.origin;
    let url = this.router.urlFor('apps.app.teams-checkout.plan', this.appService.app.id, {
      queryParams: this.queryParamsForRedirect,
    });
    return `${baseUrl}${url}`;
  }

  get adminIdsWithCopilotSeats() {
    return this.appService.app.humanAdminsWithCopilotSeat.map((admin: any) => admin.id);
  }

  get yearlyBillingPeriodSelected() {
    return this.billingPeriod === BILLING_PERIODS.Annual;
  }

  get selectedPlanIds() {
    let planIds = [Number(this.selectedPlanId)];
    if (this.selectedProactiveAddon) {
      planIds.push(Number(PROACTIVE_SUPPORT_PLUS_BASE_ID));
    }
    if (this.selectedCopilotAddon) {
      planIds.push(Number(FIN_AI_COPILOT_BASE_ID));
    }
    return planIds;
  }

  get copilotSeatNumber() {
    return this.copilotInitialised ? this.selectedCopilotAdmins.length : 0;
  }

  get selectedCopilotAddon() {
    return this.copilotSeatNumber > 0;
  }

  get sendCopilotAdminIds() {
    return this.copilotSeatNumber > 0;
  }

  get showDiscount() {
    return this.yearlyBillingPeriodSelected;
  }

  get loading() {
    return this.quoteService.loading;
  }

  get quote() {
    let planIds = [Number(this.selectedPlanId), ...this.nonCorePlanIdsToFetch];
    return this.quoteService.getQuoteById(planIds);
  }

  get fullSeatPrice() {
    return this.quote?.fullSeatPrice(Number(this.selectedPlanId), Metric.core_seat_count) || 0;
  }

  get discountedSeatPrice() {
    return this.quote?.discountedAnnualSeatPrice(Number(this.selectedPlanId)) || 0;
  }

  get fullProactiveAddonPrice() {
    return this.quote?.fullBreakdownAmount(Number(PROACTIVE_SUPPORT_PLUS_BASE_ID)) || 0;
  }
  get discountedProactiveAddonPrice() {
    return this.quote?.fullBreakdownAmount(Number(PROACTIVE_SUPPORT_PLUS_BASE_ID)) || 0;
  }

  get perSeatCopilotAddonPrice() {
    return (
      this.quote?.fullSeatPrice(Number(FIN_AI_COPILOT_BASE_ID), Metric.copilot_seat_count) || 0
    );
  }

  get fullCopilotAddonPrice() {
    return this.perSeatCopilotAddonPrice * this.selectedCopilotAdmins.length;
  }

  get discountedCopilotSeatPrice() {
    return (
      this.quote?.discountedAnnualSeatPrice(
        Number(FIN_AI_COPILOT_BASE_ID),
        Metric.copilot_seat_count,
      ) || 0
    );
  }

  get monthlyCopilotPriceForSeats() {
    let baseSeatPrice = this.yearlyBillingPeriodSelected
      ? this.discountedCopilotSeatPrice
      : this.perSeatCopilotAddonPrice;
    return baseSeatPrice * this.copilotSeatNumber;
  }

  get monthlyDiscountedTotal() {
    let total = this.monthlyDiscountedSeatPrice;
    if (this.selectedProactiveAddon) {
      total += this.fullProactiveAddonPrice;
    }
    if (this.selectedCopilotAddon) {
      total += this.monthlyCopilotPriceForSeats;
    }
    return total;
  }

  get totalPrice() {
    let total = 0;
    if (this.yearlyBillingPeriodSelected) {
      total = this.monthlyDiscountedTotal * 12;
    } else {
      total = this.monthlyTotal;
    }
    return total;
  }

  get totalPriceInCents() {
    return this.totalPrice * 100;
  }

  get totalPriceFormatted() {
    return this.intl.t('signup.teams.pricing5.annual-plans.basket.due-today-price', {
      yearly: this.yearlyBillingPeriodSelected,
      price: this.intl.formatNumber(this.totalPrice, {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
      }),
      htmlSafe: true,
    });
  }

  get monthlyFullSeatPrice() {
    return this.fullSeatPrice * this.displayedSeatNumber;
  }

  get monthlyDiscountedSeatPrice() {
    return this.discountedSeatUsagePrice;
  }

  get discountedSeatUsagePrice() {
    return this.discountedSeatPrice * this.displayedSeatNumber;
  }

  get monthlyTotal() {
    let total = this.monthlyFullSeatPrice;
    if (this.selectedProactiveAddon) {
      total += this.fullProactiveAddonPrice;
    }
    if (this.selectedCopilotAddon) {
      total += this.fullCopilotAddonPrice;
    }
    return total;
  }

  get backButtonLabel() {
    return this.intl.t('signup.teams.header.build_your_plan');
  }

  get corePlans() {
    return this.appService.app.allPlansOnPricingModel.filter(
      (plan: Plan) => !plan.product.addon && plan.selfServe,
    );
  }

  get nonCorePlanIdsToFetch() {
    return this.appService.app.allPlansOnPricingModel
      .filter((plan: Plan) => plan.product.addon && plan.selfServe)
      .map((plan: Plan) => plan.idAsNumber);
  }

  get planIdsForQuote() {
    return this.corePlans.map((plan: Plan) => [plan.idAsNumber, ...this.nonCorePlanIdsToFetch]);
  }

  get planIdsForInitialQuote() {
    return [Number(this.selectedPlanId), ...this.nonCorePlanIdsToFetch];
  }

  get showAnnualBillingSelector() {
    return this.appService.app.show5_1PricingModal;
  }

  get proactiveSupportBaseUsage() {
    return this.quote?.proactiveSupportBaseUsage;
  }

  get displayedSeatNumber() {
    if (this.shouldDisplayUnderlyingAssignedSeatNumber) {
      return this.seatNumber;
    }
    return this.selectedSeatNumber;
  }

  get shouldDisplayUnderlyingAssignedSeatNumber() {
    return (
      !this.appService.app.canUseMultipleMonthlySeatsExperiment ||
      this.yearlyBillingPeriodSelected ||
      this.showCreditCardPage
    );
  }

  get additionalSeatNumber() {
    return this.selectedSeatNumber - this.seatNumber;
  }

  get monthlyAdditionalSeatPrice() {
    return this.fullSeatPrice * this.additionalSeatNumber;
  }

  get showAdditionalSeats() {
    return (
      this.appService.app.canUseMultipleMonthlySeatsExperiment &&
      this.showCreditCardPage &&
      this.additionalSeatNumber > 0
    );
  }

  get totalCopy() {
    if (!this.appService.app.canUseMultipleMonthlySeatsExperiment || this.showCreditCardPage) {
      return this.intl.t('signup.teams.pricing5.annual-plans.basket.due-today');
    }
    return this.intl.t('signup.teams.pricing5.annual-plans.basket.total');
  }

  backToOnboardingHomeAndOpenConversionModal() {
    let queryParams = { action: 'buy_intercom' };
    this.router.transitionTo(this.appService.app.onboardingHomeRoute, { queryParams });
  }

  @action
  onBackButtonClick() {
    if (this.showCreditCardPage) {
      this.setShowCreditCardPage();
    } else {
      this.backToOnboardingHomeAndOpenConversionModal();
    }
  }

  @action showProactiveSupportProArticle() {
    window.Intercom('showArticle', this.appService.app.proactiveSupportProArticleId);
    this.sendAnalyticsEvent({
      action: 'clicked',
      object: 'metered_proactive_support_plus_article',
    });
  }

  @action
  setSelectedCopilotAdmins(selectedAdmins: string[]) {
    this.selectedCopilotAdmins = selectedAdmins;
  }

  @action
  setCopilotAsInitialised(value: boolean) {
    this.copilotInitialised = value;
  }

  @action
  setSelectedProactiveAddon(value: boolean) {
    this.selectedProactiveAddon = value;
    this.sendAnalyticsEvent({
      action: value ? 'selected' : 'unselected',
      object: 'proactive_support_addon_selector',
    });
  }

  @action
  updateBillingPeriod(value: BILLING_PERIODS) {
    this.billingPeriod = value;
    if (this.billingPeriod === BILLING_PERIODS.Annual) {
      this.seatNumber = this.selectedSeatNumber;
    }
    this.setSeatNumberToWithinMinAndMax();
    this.sendAnalyticsEvent({
      action: 'selected',
      object: 'billing_period_selector',
      context: { billing_period: `${this.billingPeriod}` },
    });
  }

  @action
  updateSelectedPlanId(plan: Plan) {
    this.selectedPlanId = plan.id;
    this.setSeatNumberToWithinMinAndMax();
    this.sendAnalyticsEvent({
      action: 'selected',
      object: 'plan_selector',
      context: { plan: this.selectedPlanId },
    });
  }

  @action
  updateSeatNumber(seatNumber: number) {
    this.selectedSeatNumber = seatNumber;
    this.seatNumber = this.selectedSeatNumber;
    this.setSeatNumberToWithinMinAndMax();
    this.sendAnalyticsEvent({
      action: 'inputed',
      object: 'seat_number_input',
      context: {
        billing_period: this.billingPeriod,
        seat_number: this.seatNumber,
        selected_seat_number: this.selectedSeatNumber,
        additional_seat_number: this.additionalSeatNumber,
        did_add_additional_seats: this.additionalSeatNumber > 0,
      },
    });
  }

  @action
  setShowCreditCardPage() {
    this.showCreditCardPage = !this.showCreditCardPage;
    if (this.showCreditCardPage) {
      this.sendAnalyticsEvent({
        action: 'clicked',
        object: 'continue_to_payment_button',
        context: {
          plans: this.selectedPlanIds,
          billing_period: `${this.billingPeriod}`,
          seat_number: `${this.seatNumber}`,
          proactive_addon: `${this.selectedProactiveAddon}`,
        },
      });

      this.sendAnalyticsEvent({
        action: 'viewed',
        object: 'pricing_5_checkout',
      });
    }
  }

  @action sendAnalyticsEvent({
    action,
    object,
    context,
  }: {
    action: string;
    object: string;
    context?: Record<string, any>;
  }) {
    this.purchaseAnalyticsService.trackEvent({
      action,
      object,
      context,
      place: 'p5-checkout-convert-to-paid',
    });
  }

  @keepLatestTask
  *getQuotes(): TaskGenerator<void> {
    let params = this.planIdsForQuote.map((planIds: string[]) => {
      return {
        ...DEFAULT_REQUEST_PARAMS,
        planIds,
        pricingModelIdentifier: PricingModelIdentifier.PRICING_5_1,
        source: 'pricing-five-checkout',
        coreSeatCount: this.seatNumber,
      };
    });
    yield taskFor(this.quoteService.getQuotes).perform(params);
  }

  @keepLatestTask
  *convertSubscription(afterPaymentMethodSuccess: AfterPaymentMethodSuccess): TaskGenerator<void> {
    let { paymentMethod, stripeIntent, address } = afterPaymentMethodSuccess;
    let paramsForAnalytics = {
      billing_period_duration_in_months: this.billingPeriod,
      seats: this.seatNumber,
      selected_seat_number: this.selectedSeatNumber,
      additional_seat_number: this.additionalSeatNumber,
      did_add_additional_seats: this.additionalSeatNumber > 0,
      copilot_seat_count: this.copilotSeatNumber,
      solution_id: this.solutionId,
      plan_ids: this.selectedPlanIds,
      payment_intent: stripeIntent?.isPaymentIntent,
    };

    let params = {
      ...paramsForAnalytics,
      country_code: address.countryCode,
      street_address: address.streetAddress,
      stripe_payment_method_id: paymentMethod,
      app_id: this.appService.app.id,
      ...(address.stateCode && {
        state_code: address.stateCode,
      }),
      ...(address.postCode && {
        postcode: address.postCode,
      }),
      ...(address.city && {
        city: address.city,
      }),
      ...(this.sendCopilotAdminIds && { copilot_admin_ids: this.selectedCopilotAdmins }),
      ...(stripeIntent?.isPaymentIntent && { stripe_payment_intent_id: stripeIntent.id }),
    };

    try {
      yield post(`/ember/customers/${this.appService.app.id}/convert_subscription`, params);
      this.notificationsService.notifyConfirmation(
        this.intl.t('signup.teams.pricing5.annual-plans.convert.success'),
      );
      if (this.appService.app.canUsePostCheckoutSuccessScreen) {
        this.router.transitionTo('apps.app.checkout.success', this.appService.app.id);
      } else {
        this.router.transitionTo('apps.app.billing.summary', this.appService.app.id);
      }
      this.sendAnalyticsEvent({
        action: 'completed',
        object: 'convert_subscription',
        context: paramsForAnalytics,
      });
    } catch (err) {
      console.error(err);
      if (err && err?.jqXHR?.responseJSON && err?.jqXHR?.responseJSON[0]) {
        this.notificationsService.notifyError(err.jqXHR.responseJSON[0]);
      } else {
        this.notificationsService.notifyError(
          this.intl.t('signup.teams.pricing5.annual-plans.stripe.generic-error'),
        );
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Signup::Teams::Pricing5::Checkouts::ConvertToPaidCheckout': typeof ConvertToPaidCheckout;
  }
}
