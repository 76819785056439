/* import __COLOCATED_TEMPLATE__ from './library-summary.hbs'; */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type ContentImportService from 'embercom/services/content-import-service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface Args {}

export default class LibrarySummary extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare notificationsService: any;
  @service declare contentImportService: ContentImportService;

  @tracked showAddSourceModal = false;

  @action async reloadContentSources() {
    this.contentImportService.contentImportSources =
      await this.contentImportService.forceFetchContentImportSources();
  }

  get contentImportSources() {
    return this.contentImportService.contentImportSources || [];
  }

  get contentLibrarySummary() {
    return this.finStandaloneService.contentLibrarySummary;
  }

  get hasAnyContent() {
    return Object.keys(this.contentLibrarySummary).some((key) => {
      return this.contentLibrarySummary[Number(key)].total_count > 0;
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Channels::LibrarySummary': typeof LibrarySummary;
    'standalone/channels/library-summary': typeof LibrarySummary;
  }
}
